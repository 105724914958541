import {useEffect, useRef, useCallback} from 'react'
import {createWebSocketConnection} from '../utils/websocket'
import {useDispatch} from 'react-redux'
import {
  getStoreReportsStatusesSuccess,
  getQBInvoicesStatusSuccess,
  getEmailQueueStatusSuccess,
} from '../redux/actions'
import debounce from 'lodash/debounce'

export const useWebSocket = (selectedStoreIds) => {
  const socketRef = useRef(null)
  const dispatch = useDispatch()

  const debouncedDispatch = useCallback(
    debounce((data) => {
      switch (true) {
        case 'reports' in data:
          const latestReports = data.reports.reduce((acc, report) => {
            const existing = acc.find((r) => r.storeId === report.storeId)
            if (
              !existing ||
              parseInt(report.jobId) > parseInt(existing.jobId)
            ) {
              acc = acc.filter((r) => r.storeId !== report.storeId)
              acc.push(report)
            }
            return acc
          }, [])

          const filteredReports = latestReports.filter((report) =>
            selectedStoreIds.includes(report.storeId),
          )
          dispatch(
            getStoreReportsStatusesSuccess({
              reports: filteredReports,
              dateRange: data.dateRange,
            }),
          )
          break

        case 'qbInvoices' in data:
          const latestQBInvoices = data.qbInvoices.reduce((acc, invoice) => {
            const existing = acc.find((i) => i.storeId === invoice.storeId)
            if (
              !existing ||
              parseInt(invoice.jobId) > parseInt(existing.jobId)
            ) {
              acc = acc.filter((i) => i.storeId !== invoice.storeId)
              acc.push(invoice)
            }
            return acc
          }, [])

          const filteredQBInvoices = latestQBInvoices.filter((invoice) =>
            selectedStoreIds.includes(invoice.storeId),
          )
          dispatch(getQBInvoicesStatusSuccess({qbInvoices: filteredQBInvoices}))
          break

        case 'emailQueue' in data:
          const latestEmailQueue = data.emailQueue.reduce((acc, email) => {
            const existing = acc.find((e) => e.storeId === email.storeId)
            if (!existing || parseInt(email.jobId) > parseInt(existing.jobId)) {
              acc = acc.filter((e) => e.storeId !== email.storeId)
              acc.push(email)
            }
            return acc
          }, [])

          const filteredEmailQueue = latestEmailQueue.filter((email) =>
            selectedStoreIds.includes(email.storeId),
          )
          dispatch(getEmailQueueStatusSuccess({emailQueue: filteredEmailQueue}))
          break

        default:
          break
      }
    }, 500),
    [dispatch, selectedStoreIds],
  )

  const handleProgressUpdate = useCallback(
    (data) => {
      debouncedDispatch(data)
    },
    [debouncedDispatch],
  )

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = createWebSocketConnection(handleProgressUpdate)
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect()
        socketRef.current = null
      }
    }
  }, [handleProgressUpdate])

  return socketRef.current
}
