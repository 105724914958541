import React, {useState, useEffect} from 'react'
import {InputLabel} from '@material-ui/core'
import styled from 'styled-components'
import {DangerButton, SecondaryButton} from '../../../components/Button'

export const RecurringFees = ({fees, setFees, setHasRecurringFeeError}) => {
  useEffect(() => {
    const hasError = fees.some((fee) => Boolean(fee.amount && !fee.frequency))
    setHasRecurringFeeError(hasError)
  }, [fees, setHasRecurringFeeError])

  const handleAmountChange = (index, value) => {
    setFees((prevFees) => {
      const newFees = [...prevFees]
      newFees[index] = {
        ...newFees[index],
        amount: value,
        frequency: value ? newFees[index].frequency : '',
      }
      return newFees
    })
  }

  const handleFrequencyChange = (index, value) => {
    setFees((prevFees) => {
      const newFees = [...prevFees]
      newFees[index] = {...newFees[index], frequency: value}
      return newFees
    })
  }

  return (
    <div>
      <StyledLabel>Recurring Fee Amount</StyledLabel>
      {fees.map((fee, index) => (
        <div key={`fee-${index}`}>
          <Container>
            <select
              value={fee.frequency}
              onChange={(e) => handleFrequencyChange(index, e.target.value)}
              style={dropdownStyle}
              required={Boolean(fee.amount)}>
              <option value="">Select Frequency</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
            <Input
              type="number"
              value={fee.amount}
              onChange={(e) => handleAmountChange(index, e.target.value)}
              placeholder="CAD$0.00"
            />
            {index > 0 && (
              <DangerButton
                style={{
                  width: '12%',
                  height: '60px',
                  fontWeight: 'bold',
                  backgroundColor: 'transparent',
                  border: '1px solid #FF4D4F',
                  color: '#FF4D4F',
                }}
                onClick={() =>
                  setFees((prev) => prev.filter((_, i) => i !== index))
                }>
                Remove
              </DangerButton>
            )}
          </Container>
          {fee.amount && !fee.frequency && (
            <ErrorMessage>
              Please select a frequency when entering a recurring fee amount
            </ErrorMessage>
          )}
        </div>
      ))}
      <SecondaryButton
        onClick={() => {
          console.log('Current fees:', fees)
          setFees((prev) => {
            console.log('Previous fees:', prev)
            const newFees = [...prev, {amount: '', frequency: ''}]
            console.log('New fees:', newFees)
            return newFees
          })
        }}>
        Add More Recurring Fees
      </SecondaryButton>
    </div>
  )
}
// Existing styled components remain the same
const Container = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
`

const dropdownStyle = {
  padding: '18.5px 14px',
  width: '45%',
  border: '1px solid #E0E0E0',
  borderRadius: '8px',
  backgroundColor: '#FFFFFF',
  fontSize: '16px',
  cursor: 'pointer',
  appearance: 'none',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 16px center',
}

const StyledLabel = styled(InputLabel)`
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: ${({theme}) => theme.colors.label};
  line-height: 2rem;
`

const Input = styled.input`
  width: 40%;
  padding: 18.5px 14px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;

  &:focus {
    outline: none;
    border-color: ${({theme}) => theme.colors.primary};
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.1);
  }
`

const ErrorMessage = styled.div`
  color: ${({theme}) => theme.colors.error || '#dc3545'};
  font-size: 14px;
`
