import React from 'react'
import styled from 'styled-components'

const SelectField = ({field, id, selectOptions, ...props}) => {
  return (
    <StyledSelect id={id} {...field} {...props}>
      {selectOptions.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </StyledSelect>
  )
}

export default SelectField

const StyledSelect = styled.select`
  padding: 14px 12px;
  width: 300px;
  border: 1px solid #e0e0e0;
  color: ${({theme}) => theme.colors.label};
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1 1L7 7L13 1' stroke='%23666666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 16px center;
  transition: border-color 0.2s ease;

  &:hover {
    border-color: #c4d5ee;
  }

  &:focus {
    outline: none;
    border-color: ${({theme}) => theme.colors.primary};
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.1);
  }
`
