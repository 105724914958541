import React, {useEffect} from 'react'
import {useLocation, useHistory} from 'react-router-dom'
import {connect} from 'react-redux'
import {handleQuickbooksCallback} from '../../redux/actions/admin'

const QuickbooksCallback = ({handleQuickbooksCallback}) => {
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const code = params.get('code')
    const state = params.get('state')
    const realmId = params.get('realmId')

    if (code) {
      handleQuickbooksCallback(code, realmId, state)
      history.push('/admin')
    }
  }, [location, handleQuickbooksCallback, history])

  return <div>Processing QuickBooks connection...</div>
}

const mapDispatchToProps = {
  handleQuickbooksCallback,
}

export default connect(null, mapDispatchToProps)(QuickbooksCallback)
