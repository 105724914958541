import React from 'react'
import {Route, Switch, useRouteMatch} from 'react-router-dom'
import styled from 'styled-components'
import Delivery from '../Delivery'
import AddNewPrescription from '../AddNewPrescription'
import DuplicateDelivery from '../DuplicateDelivery'
import Home from '../Home'

const Container = styled.div`
  width: 100%;
`

const Dashboard = () => {
  let match = useRouteMatch()

  return (
    <Container id="dashboard">
      <Switch>
        <Route
          path={`${match.url}/delivery/:deliveryId/duplicate`}
          render={() => <DuplicateDelivery rootPath={match.url} />}
        />
        <Route
          path={`${match.url}/delivery/:deliveryId/add-new-prescription/:orderId`}
          render={() => <AddNewPrescription rootPath={match.url} />}
        />
        <Route
          path={`${match.url}/delivery/:deliveryId/add-new-prescription`}
          render={() => <AddNewPrescription rootPath={match.url} />}
        />
        <Route
          path={`${match.url}/delivery/:deliveryId`}
          render={() => <Delivery rootPath={match.url} />}
        />
        <Route path={match.url} render={() => <Home rootPath={match.url} />} />
      </Switch>
    </Container>
  )
}

export default Dashboard
