import {InputLabel, Modal} from '@material-ui/core'
import React, {useEffect, useState, useMemo} from 'react'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {PrimaryButton} from '../../components/Button'
import Loading from '../../components/LoadingOrange'
import TextField from '../../components/Textfield'
import {ADMIN_EMAILS, ADMIN_TABS, PROVINCES} from '../../constants'
import DateFilter from '../../containers/DateFilter'
import {
  adminFetchStore,
  createPharmacy,
  editAdminFetchStore,
  fetchDrivers,
  fetchStores,
  addDriver,
  deleteDrivers,
  getStoreReportsStatus,
} from '../../redux/actions'
import theme from '../../theme'
import {Trash, Add} from 'iconsax-react'
import {IoCloseOutline} from 'react-icons/io5'
import {SecondaryDangerButton} from '../../components/Button'
import {CreateDriverForm} from './CreateDriverForm'
import {DriverCard} from './DriverCard'
import {Search} from '../../components/Search'
import {InvoiceDataForm} from './StoreForm/InvoiceDataForm'
import {DriverModelForm} from './StoreForm/DriverModelForm'
import {SetupFeeForm} from './StoreForm/SetupFeeForm'
import {RecurringFees} from './StoreForm/RecurringFees'
import {isValidPostalCode, isFormValid} from './utils'
import SelectField from '../../components/Select'
import {ManageStores} from './ManageStores/ManageStores'

const initialFormState = {
  storeNumber: '',
  storeName: '',
  streetAddress: '',
  storePhone: '',
  city: '',
  postalCode: '',
  state: '',
  programId: 0,
  email: '',
  firstName: '',
  lastName: '',
  isBarcodeScanningRequired: true,
  isNarcoticsCheckRequired: true,
  isScriptRunnerCard: false,
  allowSplitDropoffs: false,
  customerProfileMatch: false,
  isCollectPayments: false,
  driverModel: null,
  flatRate: '',
  distanceRates: [{distanceFrom: '', distanceTo: '', rate: ''}],
  costAddedPercentage: '',
  setupFees: [{date: '', amount: ''}],
  showSetupFees: false,
  recurringFees: [{amount: '', frequency: ''}],
  softwareModel: 'Legacy',
  tier: {
    plan: 'Custom',
    monthlyPrice: '',
    pricePerStop: '',
    stopsFrom: '',
    stopsTo: '',
  },
  additionalEmailForInvoice: '',
  additionalEmailForCashRecovery: '',
}

const Admin = ({
  loading,
  createPharmacy,
  currentAdminPage,
  drivers = [],
  stores = [],
  fetchDrivers,
  fetchStores,
  userInfo,
  adminFetchStore,
  editAdminFetchStore,
  downloadingReport,
  addDriver,
  deleteDrivers,
}) => {
  const history = useHistory()
  const sortedStores = useMemo(
    () => [...stores].sort((a, b) => a.name.localeCompare(b.name)),
    [stores],
  )

  const [formData, setFormData] = useState(initialFormState)
  const [isAddStoreForm, setIsAddStoreForm] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedStore, setSelectedStore] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedDriverForModal, setSelectedDriverForModal] = useState(null)
  const [selectedDrivers, setSelectedDrivers] = useState([])
  const [showDriverForm, setShowDriverForm] = useState(false)
  const [driverSearchTerm, setDriverSearchTerm] = useState('')
  const [hasRecurringFeeError, setHasRecurringFeeError] = useState(false)
  const [postalCodeError, setPostalCodeError] = useState('')

  const onSubmit = (e) => {
    e.preventDefault()
    const formattedPostalCode = formData.postalCode
      .replace(/[^A-Za-z0-9]/g, '')
      .toUpperCase()
      .replace(/^(.{3})(.{3})$/, '$1-$2')

    const data = {
      storeNumber: formData.storeNumber,
      storeName: formData.storeName,
      streetAddress: formData.streetAddress,
      storePhone: formData.storePhone,
      firstName: formData.firstName,
      lastName: formData.lastName,
      postalCode: formattedPostalCode,
      state: formData.state,
      city: formData.city,
      programId: Number(formData.programId),
      email: formData.email,
      createSrCard: formData.isScriptRunnerCard,
      isBarcodeScanningRequired: formData.isBarcodeScanningRequired,
      isNarcoticsCheckRequired: formData.isNarcoticsCheckRequired,
      allowSplitDropoffs: formData.allowSplitDropoffs,
      enableCustomerMatching: formData.customerProfileMatch,
      driverModel:
        formData.driverModel === 'Select Driver Payment Model'
          ? null
          : formData.driverModel,
      flatRate:
        formData.driverModel === 'Flat Rate' ? Number(formData.flatRate) : null,
      distanceRates:
        formData.driverModel === 'Distance Based'
          ? formData.distanceRates.map((rate, index) => ({
              distanceFrom: Number(rate.distanceFrom),
              distanceTo: Number(rate.distanceTo),
              rate: Number(rate.rate),
              sortOrder: index,
            }))
          : null,
      costAddedPercentage:
        formData.driverModel === 'Cost Added'
          ? Number(formData.costAddedPercentage)
          : null,
      setupFees: formData.showSetupFees
        ? formData.setupFees
            .filter((fee) => fee.date && fee.amount)
            .map((fee) => ({
              amount: Number(fee.amount),
              date: fee.date,
            }))
        : [],
      recurringFees: formData.recurringFees
        .filter((fee) => fee.amount && fee.frequency)
        .map((fee) => ({
          frequency: fee.frequency,
          amount: Number(fee.amount),
        })),
      softwareModel: formData.softwareModel,
      tier:
        formData.softwareModel === 'Tiered' && formData.tier
          ? {
              plan: 'Custom',
              stopsFrom: Number(formData.tier.stopsFrom),
              stopsTo: Number(formData.tier.stopsTo),
              monthlyPrice: Number(formData.tier.monthlyPrice),
              pricePerStop: Number(formData.tier.pricePerStop),
            }
          : null,
      additionalEmailForInvoice: formData.additionalEmailForInvoice || null,
      additionalEmailForCashRecovery:
        formData.additionalEmailForCashRecovery || null,
    }

    isEdit
      ? editAdminFetchStore({
          data,
          callback: () => {
            history.push('/admin')
          },
        })
      : createPharmacy({
          data,
          callback: () => {
            history.push('/admin')
          },
        })
  }

  useEffect(() => {
    if (ADMIN_EMAILS.includes(userInfo.email)) {
      fetchDrivers()
      fetchStores()
    } else {
      history.push('/dashboard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateFormField = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: typeof value === 'function' ? value(prev[field]) : value,
    }))
  }

  const clearForm = () => {
    setFormData(initialFormState)
  }

  const onFormEditClick = () => {
    selectedStore &&
      adminFetchStore({
        storeId: selectedStore.id,
        callback: (adminStoreInfo) => {
          setIsAddStoreForm(true)
          setIsEdit(true)
          setFormData({
            storeNumber: adminStoreInfo?.storeNumber || '',
            storeName: adminStoreInfo?.storeName || '',
            streetAddress: adminStoreInfo?.streetAddress || '',
            storePhone: adminStoreInfo?.storePhone || '',
            city: adminStoreInfo?.city || '',
            postalCode: adminStoreInfo?.postalCode || '',
            state: adminStoreInfo?.province || '',
            programId: adminStoreInfo?.programId || 0,
            email: adminStoreInfo?.email || '',
            firstName: adminStoreInfo?.firstName || '',
            lastName: adminStoreInfo?.lastName || '',
            isBarcodeScanningRequired:
              adminStoreInfo?.isBarcodeScanningRequired ?? true,
            isNarcoticsCheckRequired:
              adminStoreInfo?.isNarcoticsCheckRequired ?? true,
            allowSplitDropoffs: adminStoreInfo?.allowSplitDropoffs ?? false,
            customerProfileMatch:
              adminStoreInfo?.enableCustomerMatching ?? false,
            driverModel:
              adminStoreInfo?.driverModel ?? 'Select Driver Payment Model',
            flatRate: adminStoreInfo?.flatRate || '',
            distanceRates: adminStoreInfo?.distanceRates || [
              {distanceFrom: '', distanceTo: '', rate: ''},
            ],
            costAddedPercentage: adminStoreInfo?.costAddedPercentage || '',
            setupFees: adminStoreInfo?.setupFees || [{date: '', amount: ''}],
            showSetupFees: !!adminStoreInfo?.setupFees?.length,
            recurringFees: adminStoreInfo?.recurringFees?.length
              ? adminStoreInfo.recurringFees.map((fee) => ({
                  amount: fee.amount || '',
                  frequency: fee.frequency || '',
                }))
              : [{amount: '', frequency: ''}],
            softwareModel: adminStoreInfo?.softwareModel || 'Legacy',
            tier: adminStoreInfo?.tier || initialFormState.tier,
            additionalEmailForInvoice:
              adminStoreInfo?.additionalInvoiceEmail || '',
            additionalEmailForCashRecovery:
              adminStoreInfo?.additionalCashRecoveryEmail || '',
          })
        },
      })
  }

  const onFormAddClick = () => {
    clearForm()
    setIsEdit(false)
    setIsAddStoreForm(true)
  }

  const handleActionsClick = (driver) => {
    setSelectedDriverForModal(driver)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setSelectedDriverForModal(null)
  }

  const handleDriverSelect = (driverId) => {
    setSelectedDrivers((prev) =>
      prev.includes(driverId)
        ? prev.filter((id) => id !== driverId)
        : [...prev, driverId],
    )
  }

  const handleDeleteSelected = () => {
    if (
      window.confirm(
        `Are you sure you want to delete ${selectedDrivers.length} driver(s)?`,
      )
    ) {
      deleteDrivers(selectedDrivers.map(String))
      setSelectedDrivers([])
    }
  }

  const filteredDrivers = drivers.filter((driver) => {
    const searchLower = driverSearchTerm.toLowerCase()
    return (
      driver.driverName?.toLowerCase().includes(searchLower) ||
      driver.emailAddress?.toLowerCase().includes(searchLower) ||
      driver.driverPhone?.toLowerCase().includes(searchLower)
    )
  })

  const handlePostalCodeChange = (e) => {
    const value = e.target.value
    updateFormField('postalCode', value)

    if (value && !isValidPostalCode(value)) {
      setPostalCodeError(
        'Please enter a valid Canadian postal code (e.g., V6G-2G4)',
      )
    } else {
      setPostalCodeError('')
    }
  }

  const isFormValidCheck = () => {
    return isFormValid({
      storeNumber: formData.storeNumber,
      storeName: formData.storeName,
      streetAddress: formData.streetAddress,
      storePhone: formData.storePhone,
      city: formData.city,
      postalCode: formData.postalCode,
      state: formData.state,
      firstName: formData.firstName,
      lastName: formData.lastName,
      isScriptRunnerCard: formData.isScriptRunnerCard,
      email: formData.email,
      programId: formData.programId,
      driverModel: formData.driverModel,
      flatRate: formData.flatRate,
      distanceRates: formData.distanceRates,
      costAddedPercentage: formData.costAddedPercentage,
      setupFees: formData.setupFees,
      showSetupFees: formData.showSetupFees,
    })
  }

  const loadingSpinner = (
    <Loading
      message={downloadingReport ? 'We are working on your download' : ''}
    />
  )

  if (loading) return loadingSpinner

  return (
    <>
      {currentAdminPage === ADMIN_TABS.stores && (
        <>
          {isAddStoreForm ? (
            <>
              <BackLink onClick={() => setIsAddStoreForm(false)}>
                {' < Back'}
              </BackLink>
              <AdminContainer>
                <AdminContainerCard>
                  <Form>
                    <div>
                      <TextFieldLeft>
                        <TextField
                          type="text"
                          label="Store Number"
                          name="storeNumber"
                          id="storeNumber"
                          required
                          onChange={(e) =>
                            updateFormField('storeNumber', e.target.value)
                          }
                          value={formData.storeNumber}
                        />
                      </TextFieldLeft>
                      <TextFieldRight>
                        <TextField
                          type="text"
                          label="Store Name"
                          name="storeName"
                          id="storeName"
                          required
                          onChange={(e) =>
                            updateFormField('storeName', e.target.value)
                          }
                          value={formData.storeName}
                        />
                      </TextFieldRight>
                      <TextField
                        type="text"
                        label="Street Address"
                        name="streetAddress"
                        id="streetAddress"
                        required
                        onChange={(e) =>
                          updateFormField('streetAddress', e.target.value)
                        }
                        value={formData.streetAddress}
                      />
                      <TextField
                        type="text"
                        label="City"
                        name="city"
                        id="city"
                        required
                        onChange={(e) =>
                          updateFormField('city', e.target.value)
                        }
                        value={formData.city}
                      />
                      <TextField
                        type="text"
                        label="Postal Code"
                        name="postalCode"
                        id="postalCode"
                        required
                        onChange={handlePostalCodeChange}
                        value={formData.postalCode}
                      />
                      <ErrorMessage>{postalCodeError}</ErrorMessage>
                      <StyledLabel>Province</StyledLabel>
                      <SelectField
                        field={{
                          value: formData.state,
                          onChange: (e) =>
                            updateFormField('state', e.target.value),
                        }}
                        id="province-select"
                        selectOptions={PROVINCES}
                        required
                      />
                      <TextField
                        type="text"
                        label="Store Phone"
                        name="storePhone"
                        id="storePhone"
                        required
                        onChange={(e) =>
                          updateFormField('storePhone', e.target.value)
                        }
                        value={formData.storePhone}
                      />
                      <TextFieldLeft>
                        <TextField
                          type="text"
                          label="First Name"
                          name="firstName"
                          id="firstName"
                          required
                          onChange={(e) =>
                            updateFormField('firstName', e.target.value)
                          }
                          value={formData.firstName}
                        />
                      </TextFieldLeft>
                      <TextFieldRight>
                        <TextField
                          type="text"
                          label="Last Name"
                          name="lastName"
                          id="lastName"
                          required
                          onChange={(e) =>
                            updateFormField('lastName', e.target.value)
                          }
                          value={formData.lastName}
                        />
                      </TextFieldRight>
                      <br></br>
                      <div style={{marginTop: '40px'}}>
                        <div style={{display: 'inline-block'}}>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              updateFormField(
                                'isBarcodeScanningRequired',
                                e.target.checked,
                              )
                            }
                            checked={formData.isBarcodeScanningRequired}
                          />
                          <b>Force Barcode Scanning</b>
                        </div>
                        <div
                          style={{marginLeft: '38px', display: 'inline-block'}}>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              updateFormField(
                                'isNarcoticsCheckRequired',
                                e.target.checked,
                              )
                            }
                            checked={formData.isNarcoticsCheckRequired}
                          />
                          <b>Force Narcotics / CS proof of delivery</b>
                        </div>
                      </div>
                      <br />
                      <div style={{marginTop: '40px'}}>
                        <div style={{display: 'inline-block'}}>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              updateFormField(
                                'allowSplitDropoffs',
                                e.target.checked,
                              )
                            }
                            checked={formData.allowSplitDropoffs}
                          />
                          <b>Enable Driver Task Sharing</b>
                        </div>
                        <div
                          style={{marginLeft: '20px', display: 'inline-block'}}>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              updateFormField(
                                'customerProfileMatch',
                                e.target.checked,
                              )
                            }
                            checked={formData.customerProfileMatch}
                          />
                          <b>Enable Customer Profile Matching</b>
                        </div>
                      </div>
                      <br />

                      <div style={{marginTop: '20px'}}>
                        <div style={{display: 'inline-block'}}>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              updateFormField(
                                'isScriptRunnerCard',
                                e.target.checked,
                              )
                            }
                            checked={formData.isScriptRunnerCard}
                          />
                          <b>Create ScriptRunner Card</b>
                        </div>
                        <div
                          style={{marginLeft: '27px', display: 'inline-block'}}>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              updateFormField(
                                'isCollectPayments',
                                e.target.checked,
                              )
                            }
                          />
                          <b>Collect Payments From Customers</b>
                        </div>
                      </div>
                      <br />
                      {formData.isScriptRunnerCard && (
                        <>
                          <br />
                          <TextFieldLeft>
                            <TextField
                              type="text"
                              label="Email"
                              name="email"
                              id="email"
                              required
                              onChange={(e) =>
                                updateFormField('email', e.target.value)
                              }
                              value={formData.email}
                            />
                          </TextFieldLeft>
                          <TextFieldRight>
                            <TextField
                              type="number"
                              label="Program ID"
                              name="programId"
                              id="programId"
                              required
                              onChange={(e) =>
                                updateFormField('programId', e.target.value)
                              }
                              value={formData.programId}
                            />
                          </TextFieldRight>
                        </>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                      }}>
                      <InvoiceDataForm
                        softwareModel={formData.softwareModel}
                        setSoftwareModel={(value) =>
                          updateFormField('softwareModel', value)
                        }
                        tier={formData.tier}
                        setTier={(value) => updateFormField('tier', value)}
                      />
                      <DriverModelForm
                        driverModel={formData.driverModel}
                        setDriverModel={(value) =>
                          updateFormField('driverModel', value)
                        }
                        flatRate={formData.flatRate}
                        setFlatRate={(value) =>
                          updateFormField('flatRate', value)
                        }
                        distanceRates={formData.distanceRates}
                        setDistanceRates={(value) =>
                          updateFormField('distanceRates', value)
                        }
                        costAddedPercentage={formData.costAddedPercentage}
                        setCostAddedPercentage={(value) =>
                          updateFormField('costAddedPercentage', value)
                        }
                      />
                      <SetupFeeForm
                        setupFees={formData.setupFees}
                        setSetupFees={(value) =>
                          updateFormField('setupFees', value)
                        }
                        showSetupFees={formData.showSetupFees}
                        setShowSetupFees={(value) =>
                          updateFormField('showSetupFees', value)
                        }
                      />
                      <RecurringFees
                        fees={formData.recurringFees}
                        setFees={(fees) =>
                          updateFormField('recurringFees', fees)
                        }
                        setHasRecurringFeeError={setHasRecurringFeeError}
                      />
                      <TextField
                        type="email"
                        label="Additional Email Address for Invoice"
                        name="additionalEmailForInvoice"
                        id="additionalEmailForInvoice"
                        onChange={(e) =>
                          updateFormField(
                            'additionalEmailForInvoice',
                            e.target.value,
                          )
                        }
                        value={formData.additionalEmailForInvoice}
                      />
                      <TextField
                        type="email"
                        label="Additional Email Address for Cash Recovery"
                        name="additionalEmailForCashRecovery"
                        id="additionalEmailForCashRecovery"
                        onChange={(e) =>
                          updateFormField(
                            'additionalEmailForCashRecovery',
                            e.target.value,
                          )
                        }
                        value={formData.additionalEmailForCashRecovery}
                      />
                    </div>
                    <PrimaryButton
                      style={{marginTop: '32px'}}
                      onClick={onSubmit}
                      disabled={hasRecurringFeeError || !isFormValidCheck()}>
                      {isEdit ? 'Edit Store' : 'Create Store'}
                    </PrimaryButton>
                  </Form>
                </AdminContainerCard>
              </AdminContainer>
            </>
          ) : (
            <ManageStores
              stores={sortedStores}
              selectedStore={selectedStore}
              setSelectedStore={setSelectedStore}
              onFormAddClick={onFormAddClick}
              onFormEditClick={onFormEditClick}
            />
          )}
        </>
      )}
      {currentAdminPage === ADMIN_TABS.drivers && (
        <AdminContainer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '30px',
            }}>
            {showDriverForm ? (
              <AdminTitle>Create Driver</AdminTitle>
            ) : (
              <AdminTitle>Manage Drivers</AdminTitle>
            )}

            {!showDriverForm && (
              <PrimaryButton
                style={{width: '200px', height: '50px', fontWeight: 'bold'}}
                onClick={() => setShowDriverForm(true)}>
                <Add size={25} style={{marginRight: '10px'}} />
                ADD DRIVER
              </PrimaryButton>
            )}
          </div>
          {showDriverForm ? (
            <AdminContainerCard>
              <CreateDriverForm
                setShowDriverForm={setShowDriverForm}
                addDriver={addDriver}
              />
            </AdminContainerCard>
          ) : (
            <>
              <SearchAndActionsContainer>
                <Search
                  value={driverSearchTerm}
                  onChange={setDriverSearchTerm}
                  placeholder="Search drivers by name, email or phone number..."
                  ariaLabel="Search drivers by name, email or phone number"
                />
                <SecondaryDangerButton
                  onClick={handleDeleteSelected}
                  style={{width: '200px', height: '50px', fontWeight: 'bold'}}
                  disabled={selectedDrivers.length === 0}>
                  <Trash size={20} style={{marginRight: '10px'}} />
                  DELETE
                </SecondaryDangerButton>
              </SearchAndActionsContainer>
              <DriversGrid>
                {filteredDrivers.map((driver) => (
                  <DriverCard
                    key={driver.driverId}
                    driver={driver}
                    selectedDrivers={selectedDrivers}
                    handleDriverSelect={handleDriverSelect}
                    handleActionsClick={handleActionsClick}
                  />
                ))}
              </DriversGrid>
              <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="driver-actions-modal">
                <ModalContent>
                  <CloseButton onClick={handleCloseModal}>
                    <IoCloseOutline size={25} />
                  </CloseButton>
                  <ModalHeader>
                    <h2>{selectedDriverForModal?.driverName}</h2>
                  </ModalHeader>
                  <DateFilter
                    buttonText="Generate Report"
                    info={selectedDriverForModal}
                    action="driver"
                  />
                </ModalContent>
              </Modal>
            </>
          )}
        </AdminContainer>
      )}
    </>
  )
}

const mapStateToProps = ({
  app: {loading, currentAdminPage},
  user: {userInfo},
  admin: {drivers, stores, downloadingReport},
}) => {
  return {
    loading,
    currentAdminPage,
    userInfo,
    drivers,
    stores,
    downloadingReport,
  }
}

const mapDispatchToProps = (dispatch) => ({
  createPharmacy: (pharmacy) => dispatch(createPharmacy(pharmacy)),
  fetchDrivers: (data) => dispatch(fetchDrivers(data)),
  fetchStores: (data) => dispatch(fetchStores(data)),
  adminFetchStore: (data) => dispatch(adminFetchStore({data})),
  editAdminFetchStore: (data) => dispatch(editAdminFetchStore({data})),
  addDriver: (data) => dispatch(addDriver(data)),
  deleteDrivers: (ids) => dispatch(deleteDrivers(ids)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Admin)

const Form = styled.form`
  & label {
    display: grid;
    margin-top: 15px;
    font-size: large;
  }
  & input {
    font-size: large;
  }
`

const Container = styled.div`
  align-self: baseline;
`

const DateFilterContainer = styled.div`
  margin: 2rem 2.5rem;
`

const AdminTitle = styled.h3`
  font-size: 24px;
  float: left;
`

const BackLink = styled.p`
  font-weight: bold;
  color: ${theme.colors.primary};
  cursor: pointer;
  align-self: baseline;
  margin: 54px 0 0 54px;
`

const AdminContainerCard = styled.div`
  border-radius: 5px;
  background-color: white;
  padding: 26px;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.07);
  min-width: 350px;
  margin: 24px 0;
`

const AdminContainer = styled.div`
  padding: 24px 48px;
  width: 100%;
`

const TextFieldLeft = styled.div`
  display: inline-block;
  width: 45%;
`
const TextFieldRight = styled.div`
  display: inline-block;
  width: 45%;
  float: right;
`

const StyledLabel = styled(InputLabel)`
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: ${({theme}) => theme.colors.label};
  line-height: 2rem;
`

const DriversGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 24px;
  margin-top: 24px;
`

const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 25px;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  outline: none;
  position: relative;
`

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: ${({theme}) => theme.colors.label};
  z-index: 1;

  &:hover {
    color: ${({theme}) => theme.colors.primary};
  }
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 24px;

  h3 {
    margin: 0;
  }
`

const SearchAndActionsContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;

  ${Search} {
    max-width: 400px;
  }
`

const ErrorMessage = styled.div`
  color: ${theme.colors.error || '#ff0000'};
  font-size: 12px;
  margin-top: 4px;
  min-height: 16px;
`
