import React, {useState, useEffect, useMemo} from 'react'
import styled from 'styled-components'
import {PrimaryButton} from '../../../components/Button'
import {Search} from '../../../components/Search'
import DateFilter from '../../DateFilter'
import {connect} from 'react-redux'
import {
  getStoreReportsStatus,
  stopStoreReportsPolling,
  clearStoreReportsQueue,
  connectQuickbooks,
  generateQBInvoices,
  getQBInvoicesStatus,
  stopQBInvoicesPolling,
  clearQBInvoicesQueue,
  generateEmailQueue,
  getEmailQueueStatus,
  stopEmailQueuePolling,
  clearEmailQueue,
} from '../../../redux/actions'
import {useWebSocket} from '../../../hooks/useWebSocket'

const MultipleStores = ({
  stores,
  storeReportsQueue,
  storeReportsStatuses,
  stopStoreReportsPolling,
  clearStoreReportsQueue,
  connectQuickbooks,
  generateQBInvoices,
  qbInvoiceStatuses,
  stopQBInvoicesPolling,
  clearQBInvoicesQueue,
  generateEmailQueue,
  emailQueueStatuses,
  stopEmailQueuePolling,
  clearEmailQueue,
}) => {
  const [selectedStores, setSelectedStores] = useState(() => {
    const saved = localStorage.getItem('selectedStores')
    return saved ? JSON.parse(saved) : []
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [errorPopup, setErrorPopup] = useState({show: false, message: ''})
  useWebSocket(selectedStores)

  useEffect(() => {
    localStorage.setItem('selectedStores', JSON.stringify(selectedStores))
  }, [selectedStores])

  const handleStoreSelect = (storeId) => {
    setSelectedStores((prev) => {
      const newSelected = prev.includes(storeId)
        ? prev.filter((id) => id !== storeId)
        : [...prev, storeId]
      localStorage.setItem('selectedStores', JSON.stringify(newSelected))
      return newSelected
    })
  }

  const handleSelectAll = (e) => {
    setSelectedStores(e.target.checked ? stores.map((store) => store.id) : [])
  }

  const filteredStores = stores.filter((store) => {
    const searchLower = searchTerm.toLowerCase()
    return (
      store.name?.toLowerCase().includes(searchLower) ||
      store.status?.toLowerCase().includes(searchLower)
    )
  })

  const handleGenerateReports = () => {
    stopQBInvoicesPolling()
    clearQBInvoicesQueue()

    stopEmailQueuePolling()
    clearEmailQueue()

    setShowDatePicker(true)
  }

  const getStoreStatus = (storeId) => {
    const emailStatuses = emailQueueStatuses || []
    const qbStatuses = qbInvoiceStatuses || []
    const reportStatuses = storeReportsStatuses || []

    if (emailStatuses.length > 0) {
      const emailStatus = emailStatuses.find(
        (status) => status.storeId === storeId,
      )

      if (emailStatus?.error) {
        return {
          text: 'FAILED',
          type: 'error',
          error: emailStatus.error.toUpperCase(),
        }
      }

      if (emailStatus?.status) {
        return {
          text: emailStatus.status.toUpperCase(),
          type: emailStatus.status.toLowerCase(),
        }
      }
    }

    if (qbStatuses.length > 0) {
      const qbStatus = qbStatuses.find((status) => status.storeId === storeId)

      if (qbStatus?.error) {
        return {
          text: 'FAILED',
          type: 'error',
          error: qbStatus.error.toUpperCase(),
        }
      }

      if (qbStatus?.status) {
        return {
          text: qbStatus.status.toUpperCase(),
          type: qbStatus.status.toLowerCase(),
        }
      }
    }

    if (reportStatuses.length > 0) {
      const reportStatus = reportStatuses.find(
        (status) => status.storeId === storeId,
      )

      if (reportStatus?.error) {
        return {
          text: 'FAILED',
          type: 'error',
          error: reportStatus.error.toUpperCase(),
        }
      }

      if (reportStatus?.status) {
        return {
          text: reportStatus.status.toUpperCase(),
          type: reportStatus.status.toLowerCase(),
        }
      }
    }

    return {text: '-', type: 'default'}
  }

  const getStoreProgress = (storeId) => {
    if (emailQueueStatuses?.length) {
      const emailStatus = emailQueueStatuses.find(
        (status) => status.storeId === storeId,
      )

      return emailStatus?.progress || 0
    }

    if (qbInvoiceStatuses?.length) {
      const qbStatus = qbInvoiceStatuses.find(
        (status) => status.storeId === storeId,
      )
      return qbStatus?.progress || 0
    }

    if (!storeReportsStatuses?.length) return 0

    const reportStatus = storeReportsStatuses.find(
      (status) => status.storeId === storeId,
    )

    return reportStatus?.progress || 0
  }

  const handleClearAll = () => {
    alert(
      'Are you sure you want to clear all? You will need to re-run the reports for the selected stores.',
    )
    stopStoreReportsPolling()
    clearStoreReportsQueue()

    stopQBInvoicesPolling()
    clearQBInvoicesQueue()

    stopEmailQueuePolling()
    clearEmailQueue()

    setSelectedStores([])
    localStorage.removeItem('selectedStores')
  }

  const areSelectedStoresComplete = () => {
    if (!storeReportsStatuses?.length) return false

    return selectedStores.every((storeId) => {
      const storeStatus = storeReportsStatuses.find(
        (s) => s.storeId === storeId,
      )
      return storeStatus?.status === 'completed' && storeStatus.progress === 100
    })
  }

  const areSelectedStoresQBInvoicesComplete = () => {
    if (!qbInvoiceStatuses?.length) return false

    return selectedStores.every((storeId) => {
      const storeStatus = qbInvoiceStatuses.find((s) => s.storeId === storeId)
      return storeStatus?.status === 'completed' && storeStatus.progress === 100
    })
  }

  const handleGenerateQBInvoices = () => {
    const completedStoreIds = storeReportsStatuses
      .filter(
        (status) => status.status === 'completed' && status.progress === 100,
      )
      .map((status) => status.storeId)
    clearEmailQueue()

    generateQBInvoices(completedStoreIds)
  }

  const handleGenerateEmailQueue = () => {
    const storesWithQBInvoices = qbInvoiceStatuses
      .filter(
        (status) => status.status === 'completed' && status.progress === 100,
      )
      .map((store) => {
        const reportStatus = storeReportsStatuses.find(
          (status) => status.storeId === store.storeId,
        )
        return {
          storeId: store.storeId,
          invoiceId: store.invoiceId,
          cashInvoiceId: store.cashInvoiceId,
          reportLink: reportStatus?.regularUpload?.webLink,
        }
      })

    generateEmailQueue(storesWithQBInvoices)
  }

  const handleStatusClick = (e, status) => {
    e.stopPropagation()
    if (status.type === 'error' && status.error) {
      setErrorPopup({show: true, message: status.error})
    }
  }

  const tableRows = useMemo(() => {
    return filteredStores.map((store) => {
      const rowKey = `store-${store.id}-${Math.floor(Math.random() * 10000)}`

      return (
        <tr
          key={rowKey}
          className={selectedStores.includes(store.id) ? 'selected' : ''}
          onClick={() => handleStoreSelect(store.id)}
          style={{cursor: 'pointer'}}>
          <td onClick={(e) => e.stopPropagation()}>
            <StoreCheckbox
              type="checkbox"
              checked={selectedStores.includes(store.id)}
              onChange={() => handleStoreSelect(store.id)}
            />
          </td>
          <td>{store.name}</td>
          <td>
            <StatusTag
              status={getStoreStatus(store.id).type}
              onClick={(e) => handleStatusClick(e, getStoreStatus(store.id))}>
              {getStoreStatus(store.id).text}
            </StatusTag>
          </td>
          <td>
            <ProgressBarContainer>
              <ProgressBar width={getStoreProgress(store.id)} />
              <ProgressText>{getStoreProgress(store.id)}%</ProgressText>
            </ProgressBarContainer>
          </td>
        </tr>
      )
    })
  }, [
    filteredStores,
    selectedStores,
    getStoreStatus,
    getStoreProgress,
    handleStoreSelect,
    handleStatusClick,
  ])

  return (
    <main>
      <HeaderContainer>
        <AdminTitle>Multiple Stores</AdminTitle>
        <QuickBooksSection>
          <PrimaryButton onClick={connectQuickbooks} style={{width: '200px'}}>
            Connect QuickBooks
          </PrimaryButton>
        </QuickBooksSection>
        <ButtonContainer>
          <PrimaryButton
            style={{width: '200px', height: '60px'}}
            disabled={
              !storeReportsQueue?.length && !storeReportsStatuses?.length
            }
            onClick={handleClearAll}>
            Clear All
          </PrimaryButton>
          <PrimaryButton
            style={{width: '200px', height: '60px'}}
            disabled={selectedStores.length === 0}
            onClick={handleGenerateReports}>
            Generate Reports
          </PrimaryButton>
          <PrimaryButton
            style={{width: '200px', height: '60px'}}
            disabled={
              selectedStores.length === 0 || !areSelectedStoresComplete()
            }
            onClick={handleGenerateQBInvoices}>
            Generate QB Invoices
          </PrimaryButton>
          <PrimaryButton
            style={{width: '200px', height: '60px'}}
            disabled={
              selectedStores.length === 0 ||
              !areSelectedStoresQBInvoicesComplete()
            }
            onClick={handleGenerateEmailQueue}>
            Send Invoices
          </PrimaryButton>
        </ButtonContainer>
      </HeaderContainer>

      <SearchContainer>
        <Search
          value={searchTerm}
          onChange={setSearchTerm}
          placeholder="Search stores by name, location or manager..."
          ariaLabel="Search stores by name, location or manager"
        />
      </SearchContainer>

      <TableContainer>
        <div role="region" aria-label="Stores Table">
          <StyledTable>
            <thead>
              <tr>
                <th scope="col" width="40px">
                  <StoreCheckbox
                    type="checkbox"
                    checked={selectedStores.length === filteredStores.length}
                    onChange={handleSelectAll}
                  />
                </th>
                <th scope="col">STORE NAME</th>
                <th scope="col">STATUS</th>
                <th scope="col">PROGRESS</th>
              </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </StyledTable>
        </div>
      </TableContainer>

      {showDatePicker && (
        <>
          <Backdrop onClick={() => setShowDatePicker(false)} />
          <DatePickerModal>
            <DateFilter
              action="generateStoreReports"
              buttonText="Generate Reports"
              info={selectedStores}
              onCancel={() => setShowDatePicker(false)}
            />
          </DatePickerModal>
        </>
      )}

      {errorPopup.show && (
        <>
          <Backdrop onClick={() => setErrorPopup({show: false, message: ''})} />
          <ErrorModal>
            <ErrorTitle>Error Details</ErrorTitle>
            <ErrorMessage>{errorPopup.message}</ErrorMessage>
            <CloseButton
              onClick={() => setErrorPopup({show: false, message: ''})}>
              Close
            </CloseButton>
          </ErrorModal>
        </>
      )}
    </main>
  )
}

const AdminTitle = styled.h1`
  font-size: 24px;
  margin: 0 0 25px 0;
  padding: 0;
`

const TableContainer = styled.div`
  width: 100%;
  border-radius: 12px;
`

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;

  th,
  td {
    padding: 12px 16px;
    text-align: left;
  }

  th {
    background: white;
    font-weight: 600;
    color: #495057;
    border-bottom: 1px solid #e0e2e7;
  }

  th:nth-child(3),
  td:nth-child(3) {
    text-align: center;
  }

  tbody tr {
    transition: background-color 0.2s;

    &.selected {
      background-color: ${({theme}) => `${theme.colors.primary}10`};
    }

    &:hover {
      background-color: #f8f9fa;
    }
  }
`

const StoreCheckbox = styled.input`
  width: 18px;
  height: 18px;
  margin: 0;
  cursor: pointer;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`

const SearchContainer = styled.div`
  margin-bottom: 15px;
`

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  cursor: pointer;
`

const DatePickerModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`

export const ProgressBarContainer = styled.div`
  position: relative;
  width: 100%;
  height: 25px;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
`

export const ProgressBar = styled.div`
  height: 100%;
  width: ${(props) => props.width}%;
  background-color: #007bff;
  transition: width 0.3s ease;
`

export const ProgressText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  font-size: 12px;
  font-weight: 600;
`

export const StatusTag = styled.span`
  cursor: ${({status}) => (status === 'error' ? 'pointer' : 'default')};
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  height: 25px;
  width: 120px;
  text-align: center;
  overflow: hidden;

  ${({status}) => {
    switch (status) {
      case 'active':
        return `
          background-color: #E8F0FE;
          color: #1A73E8;
        `
      case 'completed':
        return `
          background-color: #E6F4EA;
          color: #1E8E3E;
        `
      case 'waiting':
        return `
          background-color: #FFF7E6;
          color: #B95000;
        `
      case 'error':
        return `
          background-color: #EA4335;
          color: white;
        `
      default:
        return `
          background-color: #F1F3F4;
          color: #5F6368;
        `
    }
  }}
`

const QuickBooksSection = styled.div`
  margin: 20px 0;
  padding: 15px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

export const ErrorModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 400px;
`

export const ErrorTitle = styled.h3`
  margin: 0 0 16px 0;
  color: #ea4335;
`

export const ErrorMessage = styled.p`
  margin: 0 0 16px 0;
  white-space: pre-wrap;
  word-break: break-word;
`

export const CloseButton = styled(PrimaryButton)`
  width: 100%;
`

const mapStateToProps = (state) => ({
  stores: state.admin.stores,
  storeReportsStatuses: state.admin.storeReportsStatuses,
  storeReportsQueue: state.admin.storeReportsQueue,
  qbInvoicesQueue: state.admin.qbInvoicesQueue,
  qbInvoiceStatuses: state.admin.qbInvoiceStatuses,
  emailQueueStatuses: state.admin.emailQueueStatuses,
  emailQueue: state.admin.emailQueue,
})

const mapDispatchToProps = (dispatch) => ({
  getStoreReportsStatus: (payload) => dispatch(getStoreReportsStatus(payload)),
  stopStoreReportsPolling: () => dispatch(stopStoreReportsPolling()),
  clearStoreReportsQueue: () => dispatch(clearStoreReportsQueue()),
  connectQuickbooks: () => dispatch(connectQuickbooks()),
  generateQBInvoices: (payload) => dispatch(generateQBInvoices(payload)),
  getQBInvoicesStatus: (payload) => dispatch(getQBInvoicesStatus(payload)),
  stopQBInvoicesPolling: () => dispatch(stopQBInvoicesPolling()),
  clearQBInvoicesQueue: () => dispatch(clearQBInvoicesQueue()),
  generateEmailQueue: (payload) => dispatch(generateEmailQueue(payload)),
  getEmailQueueStatus: (payload) => dispatch(getEmailQueueStatus(payload)),
  stopEmailQueuePolling: () => dispatch(stopEmailQueuePolling()),
  clearEmailQueue: () => dispatch(clearEmailQueue()),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(MultipleStores)
