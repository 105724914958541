import styled from 'styled-components'

const Divider = styled.div`
  width: 100%;
  margin: 2rem 0;
  border: 1px solid ${({theme}) => theme.colors.stroke};
  ${({css}) => css};
`

export default Divider
