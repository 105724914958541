import React from 'react'
import styled from 'styled-components'
import {InputLabel} from '@material-ui/core'
import {
  SecondaryButton,
  SecondaryDangerButton,
} from '../../../components/Button'

const DRIVER_MODELS = [
  'Select Driver Payment Model',
  'Flat Rate',
  'Distance Based',
  'Cost Added',
]

export const DriverModelForm = ({
  driverModel,
  setDriverModel,
  flatRate,
  setFlatRate,
  distanceRates,
  setDistanceRates,
  costAddedPercentage,
  setCostAddedPercentage,
}) => {
  const handleAddDistanceRate = () => {
    setDistanceRates([
      ...distanceRates,
      {distanceFrom: '', distanceTo: '', rate: ''},
    ])
  }

  const handleDistanceRateChange = (index, field, value) => {
    const newRates = [...distanceRates]
    newRates[index][field] = value
    setDistanceRates(newRates)
  }

  const handleRemoveDistanceRate = (index) => {
    setDistanceRates(distanceRates.filter((_, i) => i !== index))
  }

  return (
    <>
      <StyledLabel>Driver Payment Model *</StyledLabel>
      <SelectContainer>
        <select
          style={dropdownStyle}
          value={driverModel ?? 'Select Driver Payment Model'}
          onChange={(e) => setDriverModel(e.target.value)}>
          {DRIVER_MODELS.map((model) => (
            <option key={model} value={model}>
              {model}
            </option>
          ))}
        </select>

        {driverModel === 'Flat Rate' && (
          <Input
            type="number"
            value={flatRate}
            onChange={(e) => setFlatRate(e.target.value)}
            placeholder="Flat Rate (CAD$)"
          />
        )}

        {driverModel === 'Cost Added' && (
          <Input
            type="number"
            min="0"
            max="100"
            value={costAddedPercentage}
            onChange={(e) => setCostAddedPercentage(e.target.value)}
            placeholder="Percentage (%)"
          />
        )}
      </SelectContainer>

      {driverModel === 'Distance Based' && (
        <DistanceRatesContainer>
          {distanceRates.map((rate, index) => (
            <RateRow key={index}>
              <RateField>
                <Input
                  type="number"
                  value={rate.distanceFrom}
                  onChange={(e) =>
                    handleDistanceRateChange(
                      index,
                      'distanceFrom',
                      e.target.value,
                    )
                  }
                  placeholder="From (km)"
                />
              </RateField>
              <RateField>
                <Input
                  type="number"
                  value={rate.distanceTo}
                  onChange={(e) =>
                    handleDistanceRateChange(
                      index,
                      'distanceTo',
                      e.target.value,
                    )
                  }
                  placeholder="To (km)"
                />
              </RateField>
              <RateField>
                <Input
                  type="number"
                  value={rate.rate}
                  onChange={(e) =>
                    handleDistanceRateChange(index, 'rate', e.target.value)
                  }
                  placeholder="CAD$0.00"
                />
              </RateField>
              <SecondaryDangerButton
                style={{
                  width: '150px',
                  height: '60px',
                  fontWeight: 'bold',
                }}
                onClick={() => handleRemoveDistanceRate(index)}>
                Remove
              </SecondaryDangerButton>
            </RateRow>
          ))}
          <SecondaryButton onClick={handleAddDistanceRate}>
            Add Distance Rate
          </SecondaryButton>
        </DistanceRatesContainer>
      )}
    </>
  )
}

const DistanceRatesContainer = styled.div`
  margin-top: 1rem;
`

const RateRow = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
`

const RateField = styled.div`
  flex: 1;
`

const Input = styled.input`
  width: 100%;
  padding: 18.5px 14px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;

  &:focus {
    outline: none;
    border-color: ${({theme}) => theme.colors.primary};
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.1);
  }
`

const StyledLabel = styled(InputLabel)`
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: ${({theme}) => theme.colors.label};
  line-height: 2rem;
`

const SelectContainer = styled.div`
  display: flex;
  gap: 1rem;

  select {
    flex: 1;
  }

  input {
    flex: 1;
  }
`

const dropdownStyle = {
  padding: '18.5px 14px',
  width: '100%',
  border: '1px solid #E0E0E0',
  borderRadius: '8px',
  backgroundColor: '#FFFFFF',
  fontSize: '16px',
  cursor: 'pointer',
  appearance: 'none',
  backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1 1L7 7L13 1' stroke='%23666666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e")`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 16px center',
}
