export const API_ENDPOINT =
  process.env.REACT_APP_PROXY === 'true'
    ? 'http://localhost:8010/proxy'
    : process.env.REACT_APP_API_URL

export const API_DELIVERIES_ENDPOINT = `${API_ENDPOINT}/deliveries`
export const API_ORDERS_ENDPOINT = `${API_ENDPOINT}/orders`
export const API_STORE_ENDPOINT = `${API_ENDPOINT}/store`
export const API_DRIVERS_ENDPOINT = `${API_ENDPOINT}/drivers`
export const API_LABELS_ENDPOINT = `${API_ENDPOINT}/labelscan`
export const API_EXPORT_ENDPOINT = `${API_ENDPOINT}/export/deliveries`
export const API_EXPORT_DRIVER_ENDPOINT = `${API_ENDPOINT}/export/reports/driver`
export const API_EXPORT_STORE_ENDPOINT = `${API_ENDPOINT}/export/reports/store`
export const API_EXPORT_STORE_REPORTS_ENDPOINT = `${API_ENDPOINT}/export/reports/stores`
export const API_GET_STORE_REPORTS_STATUS_ENDPOINT = `${API_ENDPOINT}/export/reports/status`
export const API_PAYMENT_ENDPOINT = `${API_ENDPOINT}/payment`
export const API_REPORTS_ENDPOINT = `${API_ENDPOINT}/reports`
export const API_RETURNS_ENDPOINT = `${API_ENDPOINT}/returns`
export const API_ONHOLD_ORDERS_ENDPOINT = `${API_ENDPOINT}/onhold-orders`
export const API_REMOVE_ONHOLD_ENDPOINT = `${API_ENDPOINT}/remove-onhold`
export const API_TRANSACTION_ENDPOINT = `${API_ENDPOINT}/authorizations`
export const API_PAYMENT_MODAL = `${API_ENDPOINT}/store/modal`
export const API_CARDS_ENDPOINT = `${API_ENDPOINT}/credit-cards`
export const API_VOICE_INSIGHTS_ENDPOINT = `${API_ENDPOINT}/voice-insights`
export const API_ADDRESS_VALIDATION_ENDPOINT = `${API_ENDPOINT}/orders/address`
export const API_QUICKBOOKS_CONNECT_ENDPOINT = `${API_ENDPOINT}/quickbooks/connect`
export const API_QUICKBOOKS_STATUS_ENDPOINT = `${API_ENDPOINT}/quickbooks/status`
export const API_QUICKBOOKS_CALLBACK_ENDPOINT = `${API_ENDPOINT}/quickbooks/callback`
export const API_QUICKBOOKS_GENERATE_INVOICES_ENDPOINT = `${API_ENDPOINT}/quickbooks/invoices`
export const API_QUICKBOOKS_INVOICES_STATUS_ENDPOINT = `${API_ENDPOINT}/quickbooks/invoices/status`
export const API_GMAIL_SEND_INVOICES_ENDPOINT = `${API_ENDPOINT}/gmail/send-invoices`
export const API_GMAIL_STATUS_ENDPOINT = `${API_ENDPOINT}/gmail/status`

export const API_THROTTLE_TIME_MS = 2000

export const ADMIN_TABS = {
  drivers: 'DRIVERS',
  stores: 'STORES',
}

export const ADMIN_EMAILS = [
  'suresh@script-runner.com',
  'test@script-runner.com',
]

export const GOOGLE_MAPS_API = process.env.REACT_APP_GOOGLE_MAPS_API

export const PROVINCES = [
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NS',
  'NT',
  'NU',
  'ON',
  'PE',
  'QC',
  'SK',
  'YT',
]
