import React from 'react'
import {PrimaryButton} from '../../../components/Button'
import SelectField from '../../../components/Select'
import theme from '../../../theme'
import DateFilter from '../../DateFilter'
import styled from 'styled-components'

export const SingleStore = ({
  stores = [],
  selectedStore,
  setSelectedStore,
  onFormAddClick,
  onFormEditClick,
}) => {
  return (
    <>
      <AdminTitle>Single Store</AdminTitle>
      <AdminContainer>
        <div style={{width: '100%'}}>
          <label
            style={{
              display: 'block',
              marginRight: '15px',
              marginBottom: '10px',
              color: theme.colors.label,
            }}>
            <b>Store Name</b>
          </label>
          <SelectField
            field={{
              value: selectedStore ? selectedStore.name : '',
              onChange: (e) => {
                const index = stores.findIndex(
                  (store) => store.name === e.target.value,
                )
                setSelectedStore(stores[index])
              },
            }}
            id="stores-select"
            selectOptions={[
              'Choose Store',
              ...stores.map((store) => store.name),
            ]}
          />
          <ButtonContainer>
            <PrimaryButton
              style={{width: '150px', marginRight: '8px'}}
              onClick={onFormAddClick}>
              Add Store
            </PrimaryButton>
            <PrimaryButton
              style={{width: '150px'}}
              onClick={onFormEditClick}
              disabled={!selectedStore}>
              Edit Store
            </PrimaryButton>
          </ButtonContainer>
          <AdminContainerCard>
            <DateFilterContainer>
              <DateFilter
                buttonText="Generate Report"
                info={selectedStore}
                action="store"
                disabled={!selectedStore}
              />
            </DateFilterContainer>
          </AdminContainerCard>
        </div>
      </AdminContainer>
    </>
  )
}

const AdminTitle = styled.h1`
  font-size: 24px;
  margin: 0 0 24px 0;
`

const AdminContainer = styled.div`
  display: flex;
  width: 100%;
`

const ButtonContainer = styled.div`
  float: right;
  margin-bottom: 16px;
`

const AdminContainerCard = styled.div`
  background: ${theme.colors.white};
  border-radius: 4px;
  margin-top: 40px;
  width: 100%;
  clear: both;
`

const DateFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`
