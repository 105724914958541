import {io} from 'socket.io-client'

export const createWebSocketConnection = (onProgressUpdate) => {
  const socket = io(process.env.REACT_APP_API_URL || 'ws://localhost:8080', {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 5,
  })

  socket.on('connect', () => {
    console.log('WebSocket connected')
  })

  socket.on('disconnect', () => {
    console.log('WebSocket disconnected')
  })

  socket.on('report_progress', (data) => {
    if (onProgressUpdate) {
      onProgressUpdate(data)
    }
  })

  socket.on('qb_invoice_progress', (data) => {
    if (onProgressUpdate) {
      onProgressUpdate(data)
    }
  })

  socket.on('email_queue_progress', (data) => {
    if (onProgressUpdate) {
      onProgressUpdate(data)
    }
  })

  socket.on('connect_error', (error) => {
    console.error('WebSocket connection error:', error)
  })

  return socket
}
