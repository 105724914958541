import React from 'react'
import styled from 'styled-components'
import MultipleStores from './MultipleStores'
import {SingleStore} from './SingleStore'
import Divider from '../../../components/FormDivider'

export const ManageStores = ({
  stores = [],
  selectedStore,
  setSelectedStore,
  onFormAddClick,
  onFormEditClick,
}) => {
  return (
    <Container>
      <SingleStore
        stores={stores}
        selectedStore={selectedStore}
        setSelectedStore={setSelectedStore}
        onFormAddClick={onFormAddClick}
        onFormEditClick={onFormEditClick}
      />
      <Divider css={{margin: '5rem 0'}} />
      <MultipleStores />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  padding: 24px 48px;
`
